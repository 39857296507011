import React from "react";
import { BrowserRouter as Router, useRoutes, } from "react-router-dom";
import Schedular from "./schedular";

const App = () => {
    const routes = useRoutes([
        { path: "/:id", element: <Schedular /> }
    ]);
    return routes;
};
export function BaseRoutes() {
    return (
        <Router >
            <App />
        </Router>
    );
}