import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../index.css";

export default function Schedular(props) {
    const [subUrl, setSubUrl] = useState("")

    const params = useParams();

    useEffect(() => {
        if (params.id) {
            setSubUrl(params.id)
        }
        else {
            setSubUrl("")
        }
    }, [params]);

    if (!subUrl) return <></>
    else
        return <iframe id="style-3" src={`https://schedule.nylas.com/${subUrl}`} title="meeting"   width="100%" height="100%" scrolling="yes"/>
}